import React from "react"

const IndexBlock = ({ children, columns, blockClass }) => (
  <div className={`${blockClass} indexBlock`}>
    <div className={`indexBlock__innerBox indexBlock--${columns}`}>
      {/* <h2 className="indexBlock__primaryTitle">{primaryTitle}</h2> */}
      {children}
    </div>
  </div>
)

export default IndexBlock
